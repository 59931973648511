@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    @apply max-w-full scroll-pt-28 scroll-smooth bg-gp-joltik font-montserrat;
  }

  a,
  a:hover {
    @apply no-underline;
  }

  button:focus,
  input:focus,
  textarea:focus {
    @apply outline-none;
  }

  textarea {
    @apply resize-none;
  }
}

@layer components {
  .btn {
    @apply btn--base btn--pseudo bg-gp-orbeeltle text-white [&:not([disabled])]:focus:bg-gp-orbeeltle/70;
  }

  .btn--white {
    @apply btn--base btn--pseudo !border-white text-white [&:not([disabled])]:focus:bg-white/70;
  }

  .btn--alt {
    @apply btn--base btn--pseudo border border-site-accent bg-transparent text-site-accent [&:not([disabled])]:focus:bg-site-accent [&:not([disabled])]:focus:text-white;
  }

  .lnk {
    @apply lnk--base hover:underline;
  }

  .section--sm {
    @apply py-6 lg:py-8;
  }

  .section {
    @apply py-8 lg:py-14 lg:last:!pb-28;
  }

  .content {
    @apply content--base content--pseudo content--paragraphs content--headings;
  }

  .aside--block {
    @apply rounded-2xl bg-gp-electabuzz px-5 py-4 lg:px-9 lg:py-6;
  }

  .cta--block {
    @apply rounded-3xl bg-white p-6 md:p-10 lg:p-14;
  }

  .image--rounded {
    @apply rounded--borders rounded--fix overflow-hidden;
  }
}

@layer utilities {
  .btn--pseudo {
    @apply transition-colors disabled:opacity-75 [&:not([disabled])]:hover:underline;
  }

  .btn--base {
    @apply inline-flex items-center justify-center rounded-full border-2 border-transparent py-1 px-4 text-center font-bold md:py-2 md:px-6;
  }

  .lnk--base {
    @apply inline-flex flex-wrap items-center [&_svg]:mr-3;
  }

  .flex-center {
    @apply flex items-center justify-center;
  }

  .content--pseudo {
    @apply children-a:underline hover:children-a:text-gp-orbeeltle;
  }

  .content--base {
    @apply children-b:font-bold children-strong:font-bold last:[&>*]:mb-0;
  }

  .content--paragraphs {
    @apply children-p:mb-4 children-p:text-sm children-p:font-normal children-p:leading-6 md:children-p:text-base md:children-p:leading-8;
  }

  .content--headings {
    @apply children-headings:mb-4 children-h1:mb-5 children-h1:text-2xl children-h1:font-bold children-h2:mb-8 children-h2:text-xl children-h2:font-bold children-h3:text-xl md:children-h1:text-3xl md:children-h2:text-2xl xl:children-h1:text-4xl xl:children-h2:text-4xl;
  }

  .rounded--borders {
    @apply rounded--fix rounded-lg sm:rounded-2xl lg:rounded-3xl;
  }

  .title--h2 {
    @apply text-lg font-bold lg:text-2xl xl:text-3xl;
  }

  .oldskool--shadow {
    box-shadow: 10px 10px 0 #014380;
  }

  .rounded--fix {
    transform: translateZ(0);
  }

  .product--shadow--top {
    box-shadow: 0 -20px 20px rgba(0, 0, 0, 0.75);
  }

  .product--shadow--left {
    box-shadow: -20px 0 20px rgba(0, 0, 0, 0.75);
  }
}

/* WordPress helpers */

.alignright {
  @apply float-right text-center;
}

.aligncenter {
  @apply my-0 mx-auto block;
}

.wp-caption {
  @apply max-w-full;
}

.wp-caption-text {
  @apply text-center text-base font-bold;
}

/* Progress bar */

#nprogress .bar {
  @apply h-2 border-r-8 border-b-gp-joltik bg-gp-orbeeltle;
}

#nprogress .spinner-icon {
  @apply hidden;
}

#nprogress .peg {
  @apply shadow;
}

/* Form helpers */

.form-cta form {
  @apply items-end justify-center md:flex;
}

.form-cta .form-duuf-input,
.form-cta .form-duuf-textarea,
.form-cta .form-duuf-select__control {
  @apply border-transparent lg:min-w-[337px];
}

.form-cta .form-duuf-group {
  @apply md:mr-4 lg:mr-8;
}

.form-duuf-submit > button {
  @apply btn;
}

.form-duuf-textarea {
  @apply h-44 !rounded-3xl;
}

.form-duuf-label {
  @apply mb-2 flex items-center font-bold md:mb-3;
}

.form-duuf-group {
  @apply relative mb-6;
}

.form-duuf-loading {
  @apply flex-center absolute inset-0 z-10 bg-transparent;
}

.form-duuf-error {
  @apply absolute right-9 top-0 text-sm text-red-700;
}

.form-duuf-input,
.form-duuf-textarea,
.form-duuf-select__control {
  @apply w-full rounded-full border border-gp-rookidee px-6 py-2;
}

.form-duuf-file input {
  @apply hidden;
}

.form-duuf-file-button button {
  @apply btn--alt py-1 px-2;
}

/* Custom */

.swiper-pagination {
  @apply !bottom-14 hidden sm:block;
}

.swiper-pagination-bullet {
  @apply !h-5 !w-5 border-2 border-white;
}

.swiper-pagination-bullet-active {
  @apply !bg-gp-orbeeltle;
}

.swiper-container-free-mode > .swiper-wrapper {
  transition-timing-function: linear;
}

/* ----------START REACT MODAL---------- */
.ReactModal__Overlay {
  z-index: 999;
}
